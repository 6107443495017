import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Peel | François Deguire' />
    <ProjectPage
      name='Peel'
      description={`
        Open-plan office spaces are often plagued by noise and visual pollution
        which impacts worker productivity. Peel is a system of fixed and satellite
        acoustic partitions designed to integrate with a variety of contemporary
        open-plan spaces. It is offered in 3 joinable and reconfigurable modules
        with electrical connections and a satellite module. They are available
        in a variety of colours and finishes made to fit with a variety of offices.
      `}
      meta={`
        For Université de Montréal<br>
        In 2018<br>
        <a href="https://bit.ly/2QXQtIa">Full Video</a>
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    peel1: file(relativePath: { eq: "peel/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel2: file(relativePath: { eq: "peel/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel3: file(relativePath: { eq: "peel/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel4: file(relativePath: { eq: "peel/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel5: file(relativePath: { eq: "peel/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel6: file(relativePath: { eq: "peel/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel7: file(relativePath: { eq: "peel/7.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
